.errorPanel {
  display: flex;
  flex-direction: row;
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  background: white;
  padding: 20px 15px;
  justify-content: center;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  box-shadow: 10px 10px 16px -10px rgba(0, 0, 0, 0.75);
  border: 1px solid #036;
  border-radius: 5px;
}

.errorLabel {
  align-self: center;
  color: #036;
  padding-left: 15px;
  width: 81.82%;
  max-height: 132px;
  overflow-y: auto;
}

.errorAttachedFile {
  width: 100%;
}

.errorClear {
  align-self: flex-start;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.icon {
  color: #ff9300;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
}

.errorLabel::-webkit-scrollbar {
  width: 15px;
}

.errorLabel::-webkit-scrollbar-track {
  margin-top: 10px;
  margin-bottom: 10px;
}

.errorLabel::-webkit-scrollbar-thumb {
  height: 60px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 12px;
  background-color: #ff9300;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
