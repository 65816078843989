.sectionTitleC {
  position: relative;
  cursor: default;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  background-color: #036;
  align-items: center;
  border-radius: 6px;
  color: #fff;
  height: 40px;
}

.sectionTitle {
  position: relative;
  display: flex;
  font-size: 22px;
}

@media (min-width: 1366px) {
  .sectionTitle {
    font-size: 22px;
  }
}

@media (min-width: 1620px) {
  .sectionTitle {
    font-size: 26px;
  }
}

@media (min-width: 1920px) {
  .sectionTitle {
    font-size: 30px;
  }
}
