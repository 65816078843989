.url {
  cursor: pointer;
  height: 33.33%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 0px 0px 6px;
  margin-top: 1px;
  min-height: 163px;
}

.rotation {
  color: white;
  text-transform: uppercase;
  transform: rotate(-90deg);
}

.disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.5;
}
