@import url('https://fonts.googleapis.com/css?family=Titillium+Web');

.btn {
  background-color: #fff;
  border: 1px solid #036;
  border-radius: 20px;
  padding: 5px 30px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #036;
  font-size: 14px;
  font-family: 'Titillium Web', sans-serif;
  outline: none;
}

.imageBtn {
  border: 1px solid #036;
  border-radius: 12px;
  padding: 2px 12px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #036;
  font-size: 12px;
  font-family: 'Titillium Web', sans-serif;
  outline: none;
}

.disabled {
  background-color: #fff;
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

.primary {
  background-color: #036;
  color: #fff;
}

.valid {
  background-color: #6c3;
  border: 1px solid #6c3;
  color: #fff;
  cursor: pointer;
}

@media (min-width: 1366px) {
  .btn {
    font-size: 18px;
  }
  .imageBtn {
    font-size: 14px;
  }
}

@media (min-width: 1920px) {
  .btn {
    font-size: 22px;
  }
  .imageBtn {
    font-size: 16px;
  }
}

@media (min-width: 2560px) {
  .btn {
    font-size: 26px;
  }
  .imageBtn {
    font-size: 18px;
  }
}
