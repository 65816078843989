@import url(https://fonts.googleapis.com/css?family=Titillium+Web);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web);
.Button_btn__3xaJM {
  background-color: #fff;
  border: 1px solid #036;
  border-radius: 20px;
  padding: 5px 30px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #036;
  font-size: 14px;
  font-family: 'Titillium Web', sans-serif;
  outline: none;
}

.Button_imageBtn__3lEXx {
  border: 1px solid #036;
  border-radius: 12px;
  padding: 2px 12px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #036;
  font-size: 12px;
  font-family: 'Titillium Web', sans-serif;
  outline: none;
}

.Button_disabled__2c3jr {
  background-color: #fff;
  border: 1px solid #9b9b9b;
  color: #9b9b9b;
}

.Button_primary__1mTxz {
  background-color: #036;
  color: #fff;
}

.Button_valid__1Fc_x {
  background-color: #6c3;
  border: 1px solid #6c3;
  color: #fff;
  cursor: pointer;
}

@media (min-width: 1366px) {
  .Button_btn__3xaJM {
    font-size: 18px;
  }
  .Button_imageBtn__3lEXx {
    font-size: 14px;
  }
}

@media (min-width: 1920px) {
  .Button_btn__3xaJM {
    font-size: 22px;
  }
  .Button_imageBtn__3lEXx {
    font-size: 16px;
  }
}

@media (min-width: 2560px) {
  .Button_btn__3xaJM {
    font-size: 26px;
  }
  .Button_imageBtn__3lEXx {
    font-size: 18px;
  }
}

.SelectCalendar_container__3Lijk {
  font-family: "Titillium Web", sans-serif;
  border: 1px solid #036;
  position: absolute;
  height: 200px;
  width: 300px;
  padding: 0px;
  outline: none;
  border-radius: 12px;
  background: #fff;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 1em;
}

.SelectCalendar_containerOptions__1n80l {
  position: absolute;
  background-color: #fff;
  border: 1px solid #036;
  width: 250px;
  color: #036;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 5em;
}

.SelectCalendar_sections__129t8 {
  font-size: 14px;
  color: #036;
  width: 100%;
  height: 100%;
  text-align: center;
  border-bottom: 1px solid #036;
}

.SelectCalendar_selectTitle__xQIOR {
  position: absolute;
  font-size: 14px;
  color: #036;
  width: 250px;
  height: 33px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #036;
  border-radius: 6px;
  top: 5em;
}

.SelectCalendar_sections__129t8:hover {
  background-color: #6c3;
  color: #fff;
}

.SelectCalendar_sections__129t8:last-child {
  border-bottom: none;
}

.SelectCalendar_sections__129t8:hover:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.SelectCalendar_sections__129t8:hover:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.SelectCalendar_sectionsI__2GmA0 {
  padding: 6px 5px;
}

.SelectCalendar_title__Xr3Gp {
  font-size: 18px;
  color: #036;
  font-family: 'Titillium Web', sans-serif;
}

.SelectCalendar_submitButton__1L5ea {
  padding: 5px 26px;
  font-size: 18px;
  margin-top: 10px;
  margin-top: 5em;
}

.SelectCalendar_flexColumn__2zSrJ {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SelectCalendar_pointer__mU2dn {
  cursor: pointer;
}
.LockScreen_container__f-Zgk {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 7;
}

.LockScreen_itemPosition__3xnP4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ErrorPanel_errorPanel__392TP {
  display: flex;
  flex-direction: row;
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  background: white;
  padding: 20px 15px;
  justify-content: center;
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  box-shadow: 10px 10px 16px -10px rgba(0, 0, 0, 0.75);
  border: 1px solid #036;
  border-radius: 5px;
}

.ErrorPanel_errorLabel__MNSro {
  align-self: center;
  color: #036;
  padding-left: 15px;
  width: 81.82%;
  max-height: 132px;
  overflow-y: auto;
}

.ErrorPanel_errorAttachedFile__rNJH2 {
  width: 100%;
}

.ErrorPanel_errorClear__3nrDl {
  align-self: flex-start;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.ErrorPanel_icon__2AiHw {
  color: #ff9300;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
}

.ErrorPanel_errorLabel__MNSro::-webkit-scrollbar {
  width: 15px;
}

.ErrorPanel_errorLabel__MNSro::-webkit-scrollbar-track {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ErrorPanel_errorLabel__MNSro::-webkit-scrollbar-thumb {
  height: 60px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 12px;
  background-color: #ff9300;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
body {
  overflow-y: auto;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}

body::-webkit-scrollbar-track {
  border-radius: 6px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset -1px -1px 0px #ff9300, inset 1px 1px 0px #ff9300;
}

body::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #ff9300;
  border: 1px solid #fff;
}

.container {
  font-family: 'Titillium Web', sans-serif;
  font-size: 14px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.navigation {
  width: 3%;
  height: 67.97%;
}

.content {
  display: flex;
  border-left: 1px solid  #036;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.image {
  width: 25%;
  margin-left: 37%;
}
.section {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 1366px) {
  .container {
    font-size: 16px;
  }
}

@media (min-width: 1920px) {
  .container {
    font-size: 20px;
  }
}

@media (min-width: 2560px) {
  .container {
    font-size: 24px;
  }
}

@media (max-height: 712px) {
  .navigation {
    height: 484px;
  }
}

@media (max-width: 1265px) {
  .navigation {
    height: 484px;
    width: 36px;
  }
}

.tabs-container {
  padding-left: 30%;
  height: 100%;
  font-size: 18px;
  flex-direction: column;
  margin-top: 5px;
  margin-left: -8px;
}


.url {
  cursor: pointer;
  height: 33.33%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px 0px 0px 6px;
  margin-top: 1px;
  min-height: 163px;
}

.rotation {
  color: white;
  text-transform: uppercase;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.5;
}

.sectionTitleC {
  position: relative;
  cursor: default;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  background-color: #036;
  align-items: center;
  border-radius: 6px;
  color: #fff;
  height: 40px;
}

.sectionTitle {
  position: relative;
  display: flex;
  font-size: 22px;
}

@media (min-width: 1366px) {
  .sectionTitle {
    font-size: 22px;
  }
}

@media (min-width: 1620px) {
  .sectionTitle {
    font-size: 26px;
  }
}

@media (min-width: 1920px) {
  .sectionTitle {
    font-size: 30px;
  }
}

p {
  font-size: 16px;
  height: 150px;
  overflow-y: auto;
}

p::-webkit-scrollbar {
  width: 9px;
  background: transparent;
}

p::-webkit-scrollbar-track {
  border-radius: 6px;
  margin-top: 1.5%;
  margin-bottom: 2.5%;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset -1px -1px 0px #FF9300, inset 1px 1px 0px #FF9300;
}

p::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #FF9300;
  border: 1px solid #fff;
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
body {
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}

body::-webkit-scrollbar-track {
  border-radius: 6px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset -1px -1px 0px #ff9300, inset 1px 1px 0px #ff9300;
}

body::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #ff9300;
  border: 1px solid #fff;
}

.container {
  font-family: 'Titillium Web', sans-serif;
  font-size: 14px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.navigation {
  width: 3%;
  height: 67.97%;
}

.content {
  display: flex;
  border-left: 1px solid  #036;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.section {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 1366px) {
  .container {
    font-size: 16px;
  }
}

@media (min-width: 1920px) {
  .container {
    font-size: 20px;
  }
}

@media (min-width: 2560px) {
  .container {
    font-size: 24px;
  }
}

@media (max-height: 712px) {
  .navigation {
    height: 484px;
  }
}

@media (max-width: 1265px) {
  .navigation {
    height: 484px;
    width: 36px;
  }
}

