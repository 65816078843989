.container {
  font-family: "Titillium Web", sans-serif;
  border: 1px solid #036;
  position: absolute;
  height: 200px;
  width: 300px;
  padding: 0px;
  outline: none;
  border-radius: 12px;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em;
}

.containerOptions {
  position: absolute;
  background-color: #fff;
  border: 1px solid #036;
  width: 250px;
  color: #036;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 5em;
}

.sections {
  font-size: 14px;
  color: #036;
  width: 100%;
  height: 100%;
  text-align: center;
  border-bottom: 1px solid #036;
}

.selectTitle {
  position: absolute;
  font-size: 14px;
  color: #036;
  width: 250px;
  height: 33px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #036;
  border-radius: 6px;
  top: 5em;
}

.sections:hover {
  background-color: #6c3;
  color: #fff;
}

.sections:last-child {
  border-bottom: none;
}

.sections:hover:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.sections:hover:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.sectionsI {
  padding: 6px 5px;
}

.title {
  font-size: 18px;
  color: #036;
  font-family: 'Titillium Web', sans-serif;
}

.submitButton {
  padding: 5px 26px;
  font-size: 18px;
  margin-top: 10px;
  margin-top: 5em;
}

.flexColumn {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}