@import url('https://fonts.googleapis.com/css?family=Titillium+Web');
html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
body {
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}

body::-webkit-scrollbar-track {
  border-radius: 6px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset -1px -1px 0px #ff9300, inset 1px 1px 0px #ff9300;
}

body::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #ff9300;
  border: 1px solid #fff;
}

.container {
  font-family: 'Titillium Web', sans-serif;
  font-size: 14px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.navigation {
  width: 3%;
  height: 67.97%;
}

.content {
  display: flex;
  border-left: 1px solid  #036;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.section {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 1366px) {
  .container {
    font-size: 16px;
  }
}

@media (min-width: 1920px) {
  .container {
    font-size: 20px;
  }
}

@media (min-width: 2560px) {
  .container {
    font-size: 24px;
  }
}

@media (max-height: 712px) {
  .navigation {
    height: 484px;
  }
}

@media (max-width: 1265px) {
  .navigation {
    height: 484px;
    width: 36px;
  }
}
