p {
  font-size: 16px;
  height: 150px;
  overflow-y: auto;
}

p::-webkit-scrollbar {
  width: 9px;
  background: transparent;
}

p::-webkit-scrollbar-track {
  border-radius: 6px;
  margin-top: 1.5%;
  margin-bottom: 2.5%;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset -1px -1px 0px #FF9300, inset 1px 1px 0px #FF9300;
}

p::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  background-color: #FF9300;
  border: 1px solid #fff;
}
